import React from "react";
import bannerbg from "../images/banner_bg.jpg"
import logo from "../images/LogoOengoo.png"

const Banner = ({title}) => {
  return (
    <div
      className=""
      style={{
        backgroundImage:  `url(${bannerbg})`,
        backgroundPosition: "center ",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "33rem", // Set a minimum height or adjust as needed
      }}
    >
      <div className="w-[100%] relative h-[33rem] bg-opacity-10 bg-red-600 flex justify-center items-center">
        <div className="  inset-0 flex flex-col justify-center items-center">
          <h1 className="text-white text-8xl font-normal text-center mt-5">OENGOO</h1>
          <h1 className="text-white text-3xl font-normal text-center mt-2">Your fitness partner</h1>
          <h1 className="text-white text-4xl font-bold text-center mt-4">{title}</h1>
        </div>
        <img src={logo} alt="Logo" className="absolute top-0 left-0 w-1/3" />
      </div>
    </div>
  );
};

export default Banner;
