import React, { useState } from "react";
import "../../styles/home.css";
import {
  FaApple,
  FaDownload,
  FaGooglePlay,
  FaImages,
  FaSearch,
} from "react-icons/fa";
import huaweiBlack from '../../images/huawei.png';
import huaweiWhite from '../../images/huawei_white.png';

const BannerComponent = () => {
  return (
    <section className={`bg-banner relative pt-[10rem] pb-[4rem]`}>
      <div className="md:w-[80%] w-full mx-auto">
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col justify-center lg:w-1/2">
            <div className="text-center banner-content lg:text-left">
              <h3 className="mb-4 text-3xl font-extrabold lg:text-5xl">
              Gamified Fitness Experience For Your Healthy Lifestyle.
              </h3>
              <p className="mb-6 text-lg">
              A unique experience for your daily life fitness activities to compete on leaderboards and challenge your community members. 
              </p>
              <div className="flex flex-wrap justify-center gap-4 mt-6 mb-20 md:flex-nowrap md:gap-2 lg:mb-0">
                <a href="https://apps.apple.com/pk/app/oengoo-your-fitness-partner/id1634639390" target="blank" >
                  <button className="flex items-center justify-between flex-shrink-0 gap-2 px-4 py-2 text-lg font-semibold text-center text-black transition duration-300 transform bg-white border border-orange-500 rounded-full btn hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500 hover:text-white hover:scale-105">
                    <FaApple className="mr-2" size={20} />
                    <span className="flex flex-col">
                      <span className="p-0 m-0 text-xs font-light">
                        Available On
                      </span>
                      <span className="p-0 m-0 text-sm store-name">
                        App Store
                      </span>
                    </span>
                  </button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.oengoo.fitness.stepstracker" target="blank" >
                  <button className="flex items-center justify-between flex-shrink-0 gap-2 px-4 py-2 text-lg font-semibold text-center text-black transition duration-300 transform bg-white border border-orange-500 rounded-full btn hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500 hover:text-white hover:scale-105">
                    <FaGooglePlay className="mr-2" size={20} />
                    <span className="flex flex-col items-start">
                      <span className="p-0 m-0 text-xs font-light">
                        Available On
                      </span>
                      <span className="p-0 m-0 text-sm store-name">
                        Google Play
                      </span>
                    </span>
                  </button>
                </a>
                <a href="https://appgallery.huawei.com/app/C107457087" target="blank"  >
                  <HuaweiButton />
                </a>
              </div>
            </div>
          </div>
          <div className="lg:w-3/2">
            <div className="w-[100%]">
              <img
                src={require("../../images/mobile_main.png")}
                alt="Mobile Mockup"
                className="w-full mx-auto lg:w-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerComponent;



const HuaweiButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="flex items-center group justify-between flex-shrink-0 gap-2 px-4 py-2 text-lg font-semibold text-center text-black transition duration-300 transform bg-white border border-orange-500 rounded-full btn hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500 hover:text-white hover:scale-105"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? huaweiWhite : huaweiBlack}
        alt="App Gallery"
        width={20}
      />
      <span className="flex flex-col">
        <span className="p-0 m-0 text-xs font-light">Explore It On</span>
        <span className="p-0 m-0 text-sm store-name">App Gallery</span>
      </span>
    </button>
  );
};

