import React from "react";
import FeatureSlider from "./feature_slider";

const AppFeaturesSection = () => {
  return (
    <section className="pt-32 overflow-x-hidden ">
      <div className="container mx-auto">
        <div className="flex flex-col items-center">
          <h2 className="mb-4 text-4xl font-extrabold text-center ">
            Oengoo Features
          </h2>
          <p className="max-w-lg mb-8 text-center">
            Oengoo gives you the social connectivity with the fitness
            enthusiasts around you, leaderboard to compete with your fitness
            community for daily activities. 
          </p>
        </div>
        {/* Add the slider component for features here */}
        <FeatureSlider />
      </div>
    </section>
  );
};

export default AppFeaturesSection;
