import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import Teams from './pages/teams';
import Blogs from './pages/blogs/index';
import PrivacyPolicy from './pages/privacy_policy';
import AccountDeletionRequest from './pages/account_delete_request';
import ContactUs from './pages/contact-us';

function App() {
  return (
    <div className="text-black bg-white ">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Teams />} />
        <Route path="/blogs/:id" element={<Blogs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/account-delete-request" element={<AccountDeletionRequest />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
