import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useParams } from "react-router-dom";
import blogsData from "../../data/blogs_data";
import Banner from "../../components/banner";

function Blogs() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const getBlog = () => {
    const selectedBlog = blogsData.find((blog) => blog.id == id);
    setBlog(selectedBlog);
  };
  console.log(blog)
  useEffect(() => getBlog(), [id]);
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []); 
  return (
    <div>
      <Navbar />
      {blog ? (
        <>
          
          <Banner title={blog.title} />

          <div className="lg:w-[80%] w-full lg:p-0 px-4 mt-16 mx-auto">
            {/* image */}
            <div>
              <img
                src={blog.image}
                alt="Blog Post"
                className="w-full rounded-md h-[19rem] object-cover object-center"
              />
            </div>
            <div className="my-10 md:ms-10 flex flex-col gap-5">
              {/* title */}
              <h1 className="md:text-3xl text-2xl font-bold">{blog.title}</h1>
              {/* by */}
              <p>
                By: <span>Oengoo</span>
              </p>
              {/* description */}
              {/* Render content subheadings and paragraphs */}
              {blog.content.map((contentItem, index) => (
                <div key={index} className="my-6">
                  <h2 className="text-xl font-semibold">
                    {contentItem.subheading}
                  </h2>
                  <p className="text-gray-700">{contentItem.paragraph}</p>
                </div>
              ))}
              {/* tags */}
              {/* <div className="flex flex-wrap gap-4 items-center">
                <p className="font-bold">Tags:</p>
                <div className="flex flex-wrap gap-4 items-center">
                  <div className="px-3 py-2 border ">News</div>
                  <div className="px-3 py-2 border ">News</div>
                </div>
              </div> */}

              {/* share this */}
              {/* <div className="flex flex-col flex-wrap gap-5 justify-center mt-5">
                <p className="font-bold">Share this:</p>
                <div className="flex flex-wrap gap-2 mt-4">
                  <button className="px-3 py-2 rounded-md bg-blue-500 text-white flex items-center">
                    <FaFacebook className="mr-2" />
                    Facebook
                  </button>
                  <button className="px-3 py-2 rounded-md bg-blue-400 text-white flex items-center">
                    <FaTwitter className="mr-2" />
                    Twitter
                  </button>
                  <button className="px-3 py-2 rounded-md bg-red-500 text-white flex items-center">
                    <FaPinterest className="mr-2" />
                    Pinterest
                  </button>
                  <button className="px-3 py-2 rounded-md bg-gray-800 text-white flex items-center">
                    <FaEnvelope className="mr-2" />
                    Mail
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
          <h5 className="font-bold text-center text-md">Loading ....</h5>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Blogs;
