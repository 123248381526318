import React, { useState } from "react";
import {
  FaPhone,
  FaEnvelope,
  FaLinkedinIn,
  FaFacebookF,
  FaBehance,
  FaTwitter,
  FaApple
} from "react-icons/fa";

const TeamCard = ({ name, image, designation, linkedIn }) => {
  const [isShareHovered, setIsShareHovered] = useState(false);
  return (
    <div className="lg:w-[23.5%] md:w-[40%] w-[80%] ">
      <div className="relative pt-20 group">
        <div className="">
          <img
            decoding="async"
            src={image}
            alt=""
            className="w-44 h-44 top-0 left-3 absolute object-cover m-2 rounded-full group-hover:border-orange-500 group-hover:border-[3px] group-hover:p-[2px]"
          />
        </div>
        <div className="p-4 pt-32 bg-white shadow-lg rounded-xl hover:shadow-2xl">
          <div className="flex flex-row h-full flex-nowrap">
            <div className="flex-1">
              <h3 className="mb-2 text-xl font-bold">{name}</h3>
              <p className="mb-2 text-sm text-gray-600">{designation}</p>
              {/* <div className="mb-2 s2_mem_contact">
                <span className="flex items-center gap-2 mr-2">
                  <FaPhone className="inline text-orange-500" />
                  121-3245-214
                </span>
                <span className="flex items-center gap-2">
                  <FaEnvelope className="inline text-orange-500" />
                  sample@mail.com
                </span>
              </div> */}
            </div>
            <div className="flex relative flex-row items-end h-[3.5rem] group-[socialMedia] ">
              <a href={linkedIn} target="_blank">
                <button className="z-10 p-2 text-white bg-orange-500 rounded-full">
                  <FaLinkedinIn size={20} />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
