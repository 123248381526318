import React, { useState } from "react";
import { RiUserLine } from "react-icons/ri";
import { FaDownload, FaGlobeAmericas, FaImages } from "react-icons/fa";
import { FiShield } from "react-icons/fi";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import huaweiBlack from '../../images/huawei.png';
import huaweiWhite from '../../images/huawei_white.png';

const HowAppWorksSection = () => {
  return (
    <section id="download" className="py-16 mt-10">
      <div className="container flex flex-wrap items-center mx-auto">
        <div className="w-full md:w-1/2 md:pr-10">
          <div className="">
            <img
              src={require("../../images/mobile_main.png")}
              alt="Mobile Mockup"
              className="w-full mx-auto hover:scale-110  lg:w-[100%] "
            />
          </div>
        </div>
        <div className="w-full mt-8 md:w-1/2 md:mt-0">
          <div className="mb-6 text-center title">
            <h3 className="text-3xl font-bold">How does this App Work?</h3>
          </div>
          <div className="flex flex-col w-full p-5 mx-auto my-10 lg:w-1/2 md:3/2 md:p-0">
            <div className="flex items-center mb-6 single-how-app-work">
              <div className="p-3 rounded-full icon-box bg-gradient-to-r from-orange-500 to-red-500">
                <FaDownload className="w-8 h-8 text-white" />
              </div>
              <div className="ml-4 text-box">
                <h4 className="text-xl font-semibold">Download it for Free</h4>
                <p className="text-gray-700">
                  We use a customized application specifically designed for
                  testing to keep away from people.
                </p>
              </div>
            </div>
            <div className="flex items-center mb-6 single-how-app-work">
              <div className="p-3 rounded-full icon-box bg-gradient-to-r from-orange-500 to-red-500">
                <RiUserLine className="w-8 h-8 text-white" />
              </div>
              <div className="ml-4 text-box">
                <h4 className="text-xl font-semibold">Make a profile</h4>
                <p className="text-gray-700">
                  We use a customized application specifically designed for
                  testing to keep away from people.
                </p>
              </div>
            </div>

            <div className="flex items-center single-how-app-work">
              <div className="p-3 rounded-full icon-box bg-gradient-to-r from-orange-500 to-red-500">
                <FiShield className="w-8 h-8 text-white" />
              </div>
              <div className="ml-4 text-box">
                <h4 className="text-xl font-semibold">Enjoy this App</h4>
                <p className="text-gray-700">
                  We use a customized application specifically designed for
                  testing to keep away from people.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-4 mt-6 mb-20 md:flex-nowrap md:gap-2 lg:mb-0">
            <a
              href="https://apps.apple.com/pk/app/oengoo-your-fitness-partner/id1634639390"
              target="blank"
            >
              <button className="flex items-center justify-between flex-shrink-0 gap-2 px-4 py-2 text-lg font-semibold text-center text-black transition duration-300 transform bg-white border border-orange-500 rounded-full btn hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500 hover:text-white hover:scale-105">
                <FaApple className="mr-2" size={20} />
                <span className="flex flex-col">
                  <span className="p-0 m-0 text-xs font-light">
                    Available On
                  </span>
                  <span className="p-0 m-0 text-sm store-name">App Store</span>
                </span>
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.oengoo.fitness.stepstracker"
              target="blank"
            >
              <button className="flex items-center justify-between flex-shrink-0 gap-2 px-4 py-2 text-lg font-semibold text-center text-black transition duration-300 transform bg-white border border-orange-500 rounded-full btn hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500 hover:text-white hover:scale-105">
                <FaGooglePlay className="mr-2" size={20} />
                <span className="flex flex-col items-start">
                  <span className="p-0 m-0 text-xs font-light">
                    Available On
                  </span>
                  <span className="p-0 m-0 text-sm store-name">
                    Google Play
                  </span>
                </span>
              </button>
            </a>
            <a
              href="https://appgallery.huawei.com/app/C107457087"
              target="blank"
            >
             <HuaweiButton />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowAppWorksSection;


const HuaweiButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className="flex items-center group justify-between flex-shrink-0 gap-2 px-4 py-2 text-lg font-semibold text-center text-black transition duration-300 transform bg-white border border-orange-500 rounded-full btn hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500 hover:text-white hover:scale-105"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? huaweiWhite : huaweiBlack}
        alt="App Gallery"
        width={20}
      />
      <span className="flex flex-col">
        <span className="p-0 m-0 text-xs font-light">Explore It On</span>
        <span className="p-0 m-0 text-sm store-name">App Gallery</span>
      </span>
    </button>
  );
};

