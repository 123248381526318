import React, { useEffect, useState } from "react";
import {
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";

const clientReviews = [
    {
        text: "I worked on Oengoo for more than 2 years now, from crafting the concept to our MVP released on Google Play and App Store - I wish everyone stay fit and healthy and Oengoo will contribute to this cause. ",
        author: "Muhammad Hilal",
        position: "Chairman IGDA Pakistan",
        profile: require("../../images/Hilal.jpg"),
      },
      {
        text: "Oengoo is a revolutionary fitness app that's taken my workouts to a new level. Its gamified approach makes exercise incredibly engaging, and the global community aspect is motivating. With Oengoo, I've achieved my fitness goals and transformed my body while having a blast. Highly recommend!",
        author: "A. Manan",
        position: "MB Law",
        profile: require("../../images/AManan.png"),

      },
      {
        text: "Oengoo has been my go-to fitness app for jogging and walking. The immersive experience and global community have motivated me to lace up my shoes every day. Thanks to Oengoo, I've found a new love for running and walking, keeping me on track with my fitness goals.",
        author: "Ahsan Hamid Durrani",
        position: "CEO - Policy Research Center PRC",
        profile: require("../../images/Ahsan.jpeg"),

      },
  // Add more reviews as needed
];

function Clients() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % clientReviews.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + clientReviews.length) % clientReviews.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % clientReviews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="md:w-[80%] w-full mx-auto py-16">
      <div className="flex flex-wrap items-center mx-auto">
        {/* First Section */}
        <div className="relative w-full my-20 md:w-1/2 md:mt-0">
          {/* Mobile image */}
          <img
            src={clientReviews[currentIndex].profile} // Replace with your mobile image path
            alt="Profile"
            className="object-cover mx-auto rounded-full shadow-lg h-72 w-60 lg:w-72 hover:shadow-orange-200 "
          />

          <img
            src={require("../../images/dots.png")}
            alt="profile side" 
            className="absolute w-16 rounded-full right-1/2 -top-28" 
            /> 
            <img
            src={require("../../images/dots.png")}
            alt="profile side" 
            className="absolute w-16  rounded-full left-[15%] -top-10" 
            /> 
             <img
            src={require("../../images/dots.png")}
            alt="profile side" 
            className="absolute w-16  rounded-full left-[10%] top-1/2" 
            /> 

             <img
            src={require("../../images/dots.png")}
            alt="profile side" 
            className="absolute w-16  rounded-full left-[24%] -bottom-20" 
            /> 
            <img
            src={require("../../images/dots.png")}
            alt="profile side" 
            className="absolute w-16  rounded-full right-[33%] -bottom-24" 
            /> 
        </div>
        {/* ... */}
        {/* Second Section */}
        <div className="w-full p-5 md:w-1/2 md:pr-10">
          <div className="flex flex-col items-start justify-start gap-3 mb-6">
            {/* ... */}
            <div className="flex flex-col gap-2">
              <h3 className="mb-2 text-3xl font-semibold md:text-4xl">
                Some Talk of Our <br />
                Honorable Users
              </h3>
              </div>
            <div className="my-4">
              {/* <img
                className="object-contain w-20 h-20"
                src="https://themexriver.com/appilo-theme/main/wp-content/uploads/sites/2/2019/12/testi-qoute-1-13-1-1-3-1.png"
                alt="comma"
              /> */}
            </div>
            <p className="mb-4 text-gray-700 h-32 line-clamp-6">
              {clientReviews[currentIndex].text}
            </p>
            <p className="flex items-center text-xl font-semibold text-gray-800">
              {clientReviews[currentIndex].author}
            </p>
            <p className="flex items-center mt-2 font-semibold text-blue-500 text-md">
              {clientReviews[currentIndex].position}
            </p>
            <div className="flex gap-4 mt-3">
              <button
                className="flex items-center font-semibold text-blue-500 hover:underline"
                onClick={handlePrev}
              >
                <FaArrowLeft className="ml-1" size={20} />
              </button>
              <button
                className="flex items-center font-semibold text-blue-500 hover:underline"
                onClick={handleNext}
              >
                <FaArrowRight className="ml-1" size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
