import React from "react";
import { FaCog, FaArrowRight } from "react-icons/fa";
import ScreenshotSlider from "./app_screenshot";

import socket from "../../images/SocketIo.svg";
import nodejs from "../../images/NodeLogo.svg";
import react from "../../images/React.svg";
import flutter from "../../images/flutter.svg";
import kotlin from "../../images/kotlinlang-icon.svg";

function Discover() {
  return (
    <div>
      <FirstDiscoverComponent />
      <SecondDiscoverComponent />
      <ScreenshotSlider />
      <ThirdDiscoverComponent />
    </div>
  );
}

export default Discover;

function FirstDiscoverComponent() {
  return (
    <section className="md:w-[80%] w-full mx-auto py-16">
      <div className="flex flex-wrap items-center mx-auto ">
        {/* First Section */}
        <div className="w-full p-5 md:w-2/5 md:pr-10">
          {/* Col with React icons, heading, paragraph, and discover link */}
          {/* Add appropriate React icons */}
          <div className="flex flex-col items-start justify-start gap-8 mb-6">
            <div className="p-4 mr-4 text-orange-500 bg-white rounded-full hover:text-white icon-box hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500">
              <FaCog size={40} />
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="mb-2 text-3xl font-semibold md:text-4xl">
                Designed to give the best user experience to our users
              </h3>
              <p className="mb-4 text-gray-700">
                Beat your personal best – Be the best in the city – Rise up the
                leaderboard and beat challenges one by one. Find your Jogging
                mate to keep you motivated for your next challenge.
              </p>
              {/* <a
                href="https://www.aptechmedia.com"
                target="_blank"
                className="flex items-center font-semibold text-blue-500 hover:underline"
              >
                Discover More
                <FaArrowRight className="ml-1" />
              </a> */}
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div className="flex items-center justify-center w-full mt-8 md:w-3/5 md:mt-0">
          {/* Mobile image */}

          <div>
            <img
              src={require("../../images/mobile_community_frame.png")} // Replace with your mobile image path
              alt="Mobile "
              className="md:m-0  mx-auto w-[10rem] lg:w-[15rem] hover:scale-110"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function SecondDiscoverComponent() {
  return (
    <section className="md:w-[80%] w-full mx-auto py-16">
      <div className="flex flex-wrap items-center mx-auto ">
        {/* First  Section */}
        <div className="w-full mt-8 md:w-3/5 md:mt-0">
          {/* Mobile image */}
          <img
            src={require("../../images/mobile_down.png")} // Replace with your mobile image path
            alt="Mobile"
            className="w-full mx-auto lg:w-auto hover:scale-110"
          />
        </div>

        {/* Second Section */}
        <div className="w-full p-5 md:w-2/5 md:pr-10">
          {/* Col with React icons, heading, paragraph, and discover link */}
          {/* Add appropriate React icons */}
          <div className="flex flex-col items-start justify-start gap-8 mb-6">
            <div className="p-4 mr-4 text-orange-500 bg-white rounded-full hover:text-white icon-box hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500">
              <FaCog size={40} />
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="mb-2 text-3xl font-semibold md:text-4xl">
                Responsive Design for All Devices with Quality
              </h3>
              <p className="mb-4 text-gray-700">
                Oengoo gives you the social connectivity with the fitness
                enthusiasts around you, leaderboard to compete with your fitness
                community for daily activities.
              </p>
              {/* <a
                href="https://www.aptechmedia.com"
                target="_blank"
                className="flex items-center font-semibold text-blue-500 hover:underline"
              >
                Discover More
                <FaArrowRight className="ml-1" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ThirdDiscoverComponent() {
  return (
    <section className="md:w-[80%] w-full mx-auto py-16">
      <div className="flex flex-wrap items-center mx-auto ">
        {/* First Section */}
        <div className="w-full p-5 md:w-2/5 md:pr-10">
          {/* Col with React icons, heading, paragraph, and discover link */}
          {/* Add appropriate React icons */}
          <div className="flex flex-col items-start justify-start gap-8 mb-6">
            {/* <div className="p-4 mr-4 text-orange-500 bg-white rounded-full hover:text-white icon-box hover:bg-gradient-to-r hover:from-orange-500 hover:to-red-500">
              <FaCog size={40} />
            </div> */}
            <div className="flex flex-col gap-2">
              <h3 className="mb-2 text-3xl font-semibold md:text-4xl">
                Designed & Worked by the Latest Integration
              </h3>
              <p className="mb-4 text-gray-700">
                We developed Oengoo with Aptechmedia using the best technologies
                to give you a seamless and smooth experience of Oengoo - Your
                Fitness Partner.
              </p>
              {/* <a
               href="https://www.aptechmedia.com"
               target="_blank"
                className="flex items-center font-semibold text-blue-500 hover:underline"
              >
                Discover More
                <FaArrowRight className="ml-1" />
              </a> */}
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div className="w-full mt-8 md:w-3/5 md:mt-0">
          {/* Mobile image */}
          <div className="flex flex-wrap items-center justify-center gap-4">
            <div className="flex items-center justify-center gap-2 flex-nowrap md:justify-center">
              <img
                src={nodejs} // Replace with your mobile image path
                alt="Mobile"
                className="w-1/4 p-4 rounded-md hover:scale-110 md:w-2/12 lg:w-2/12 bg-slate-300 hover:shadow-lg hover:shadow-orange-200"
              />
              <img
                src={flutter} // Replace with your mobile image path
                alt="Mobile "
                className="w-1/3 p-4 rounded-md bg-slate-300 md:w-2/12 lg:w-1/6 hover:shadow-lg hover:shadow-orange-200"
              />
            </div>
            <div className="flex items-start justify-center gap-2 flex-nowrap md:justify-center md:items-start">
              <img
                src={react} // Replace with your mobile image path
                alt="Mobile "
                className="w-1/4 p-4 bg-red-100 rounded-md md:w-3/12 lg:w-1/5 hover:shadow-lg hover:shadow-orange-200 "
              />
               <img
                src={socket} // Replace with your mobile image path
                alt="Mobile "
                className="w-1/3 p-4 bg-blue-100 rounded-md md:w-3/12 lg:w-1/5 hover:shadow-lg hover:shadow-orange-200"
              />

              <img
                src={kotlin} // Replace with your mobile image path
                alt="Mobile "
                className="w-1/6 p-4 bg-gray-200 rounded-md md:w-1/3 lg:w-1/5 hover:shadow-lg hover:shadow-orange-200"
              />
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
