import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaInstagram,
  FaAngleLeft,
  FaAngleRight,
  FaLinkedin
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
const logo = require("../images/oengoo_logo_color.png");

const Footer = () => {
  return (
    <footer className="p-4 bg-gray-200 appilo-footer md:pt-16 ">
      <div className="md:w-[80%] w-[100%] mx-auto">
        <div className="subscribe-section">
          <div className="container">
            <div className="text-center appilo-sec-title">
              <h2 className="text-2xl font-bold ">
                Subscribe to Our Newsletter
              </h2>
              <p className="text-md">
                Building you fitness streak with your friends. A streak of
                daily steps to keep you motivated. <br />
                Keep moving – Keep Winning
              </p>
            </div>
            <div>
              {/* Form goes here */}
              <SubscribeForm />
            </div>
          </div>
        </div>

        <div className="md:py-10">
          <div className="container">
            <div className="flex flex-col items-start justify-center gap-4 md:flex-row md:gap-10">
              {/* About Widget */}
              <div className="flex-1">
                <div className="about-widget">
                  <Link to="/" className="">
                    <img src={logo} alt="Main Logo" className="w-32" />
                  </Link>
                  <p className="mt-5 font-bold ">
                    Be the first to find out about exclusive deals, the latest
                    Lookbooks trends. We're on a mission to build a better
                    future where technology.
                  </p>
                  <div className="flex items-center gap-4 mt-3 text-gray-600">
                    <a
                      href="https://web.facebook.com/Oengoo/?locale2=fr_FR&_rdc=1&_rdr"
                      target="blank"
                    >
                      <FaFacebookF />
                    </a>
                    <a href="https://twitter.com/oengoo" target="blank">
                      <FaTwitter />
                    </a>

                    <a
                      href="https://www.instagram.com/oengooapp/?igshid=MzRlODBiNWFlZA%3D%3D"
                      target="blank"
                    >
                      <FaInstagram />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/oengoo/"
                      target="blank"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              {/* Address Widget */}
              <div className="flex-1">
                <div className="title">
                  <h3 className="text-xl font-bold">Address</h3>
                </div>
                <div className="flex flex-col gap-1 mt-5 md:mt-8">
                  {/* <p className="text-sm font-bold text-gray-600">
                    <span className="text-black">Phone:</span> +1 605 722 2032
                  </p> */}
                  <p className="text-sm font-bold text-gray-600">
                    <span className="text-black">Email: </span>{" "}
                    publishing@oengoo.com
                  </p>
                  {/* <p className="text-sm font-bold text-gray-600">
                    <span className="text-black">Address:</span>Charlotte, North Carolina, United
                    States
                  </p> */}
                </div>
              </div>

              {/* Quick Links Widget */}
              <div className="flex-1">
                <div className="title">
                  <h3 className="text-xl font-bold">Quick Links</h3>
                </div>
                <div className="mt-5 md:mt-8">
                  <ul className="flex flex-col gap-1">
                    <li className="text-sm font-bold text-gray-600">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                   
                    <li className="text-sm font-bold text-gray-600">
                      <Link to="/contact-us" className="">
                        Contact Us
                      </Link>
                    </li>
                    <li className="text-sm font-bold text-gray-600">
                      <Link to="/account-delete-request" className="">
                        Account Deletion Request
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              {/* // Twitter Widget
              <div className="flex-1">
                <div className="title">
                  <h3 className="text-xl font-bold">Twitter</h3>
                </div>
                <div className="flex flex-col gap-1 mt-5 md:mt-8">
                  <div className="">
                   //  Twitter feeds 
                    <div className="">
                      <div className="">
                        <div
                        >
                          <div className="">
                            <div className="">
                              <p className="font-bold text-gray-600 text-md">
                              Lorem ipsum
                                dolor sit amet, con sectetur adipiscing elit,
                                sed do eius mod tempor incididunt.
                              </p>{" "}
                             
                            </div>
                          </div>
                        </div>
                       // ... (More Twitter feed items) 
                      </div>
                    </div>
                    <div className="flex items-center gap-3 mt-2">
                      <div className="p-2 bg-orange-500 rounded-full">
                        <FaAngleLeft />
                      </div>
                      <div className="p-2 bg-orange-500 rounded-full">
                        <FaAngleRight />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* <div className="footer-bottom">
          <div className="container">
            <div className="footer-copyright">
              <div className="float-left left-content">
                <p>
                  © 2018, appilo Theme by Themexriver Team{" "}
                  <span className="sep">|</span>{" "}
                  <a href="#"> Privacy Policy </a>{" "}
                  <span className="sep">|</span> <a href="#"> Site Map </a>
                </p>
              </div>
              <div className="float-right right-content">
                <p>All Right Reserved</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;

const SubscribeForm = () => {
  return (
    <div className="w-[100%] mx-auto my-4 md:my-8 lg:w-2/3 md:w-4/5">
      <form action="/" method="post">
        <div className="flex justify-between px-2 py-2 text-lg font-semibold text-white transition duration-300 transform rounded-full shadow-lg md:py-8 md:px-10 item-center itms bg-gradient-to-r from-orange-500 to-red-500 ">
          <p className="flex items-center gap-2 ">
            <HiOutlineMail className="text-[13px] md:text-[22px]" />
            <span className="">
              <input
                className="w-full text-xs text-white bg-transparent border-none outline-none placeholder:text-white md:text-lg"
                placeholder="publishing@oengoo.com"
                type="email"
                name="your-email"
              />
            </span>
          </p>
          <button
            className="inline-block px-2 py-2 text-[8px] font-semibold text-white transition duration-300 transform rounded-full shadow-lg md:px-8 md:py-3 md:text-lg bg-gradient-to-r from-orange-500 to-red-500 hover:text-black hover:scale-105"
            type="button"
          >
            Subscribe Now
          </button>
        </div>
      </form>
    </div>
  );
};
